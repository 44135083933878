import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { initiateCheckout, setAmountValue, sendStoreDataToServer, updateMetadata } from "../../app/paymentSlice";
import { getRedirectUrl } from "../../util/redirect";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import StateDropdown from './StateDropdown';


const type = "card";


export const PaymentContainer = () => {
  // const { type } = useParams();

  return (
    <div id="payment-page">
      <div className="container">
        <Checkout type={type} />
      </div>
    </div>
  );
}

const Checkout = () => {
  const dispatch = useDispatch();
  const payment = useSelector(state => state.payment);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');


  console.log(payment)

   // Get the current amount value from the Redux store (optional)
   const currentAmount = useSelector((state) => state.payment.config.paymentMethodsConfiguration.card.amount.value);
  const [amount, setAmount] = useState(currentAmount);
  const [confirmedContractNumber, setConfirmedContractNumber] = useState('');
  console.log(currentAmount);


  const handleAmountChange = (e) => {
    console.log(e)
    const newAmount = parseFloat(e.target.value); // Get the input value as a number
    setAmount(newAmount); // Update local state for controlled input

    // Convert dollars to minor units (cents) and dispatch the action
    dispatch(setAmountValue(newAmount * 100));
    dispatch(sendStoreDataToServer());
  };

  const navigate = useNavigate();

  const paymentContainer = useRef(null);
  // console.log(paymentContainer)

  // const { type } = useParams();

  useEffect(() => {
    dispatch(initiateCheckout(type));
  }, [dispatch])


  useEffect(() => {
    const { error } = payment;

    if (error) {
      navigate(`/status/error?reason=${error}`, { replace: true });
    }
  }, [payment, navigate])


  useEffect(() => {
    const { config, session } = payment;
    let ignore = false;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    const createCheckout = async () => {
      console.log('in checkout')
      const checkout = await AdyenCheckout({
        ...config,
        session,
        onPaymentCompleted: (response, _component) =>
          navigate(getRedirectUrl(response.resultCode), { replace: true }),
        onError: (error, _component) => {
          console.error(error);
          navigate(`/status/error?reason=${error.message}`, { replace: true });
        },
      });
      // console.log(checkout);

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (paymentContainer.current && !ignore) {
        checkout.create(type).mount(paymentContainer.current);
      }
    }

    createCheckout();

    return () => {
      ignore = true;
    }
  }, [payment, navigate, currentAmount])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log('false')
    }
    console.log('not false')

    setValidated(true);
  };

  // Function to handle validation check
const validateContractNumberMatch = (value) => {
  console.log(validated)
  if (value !== payment.metadata.contractNumber) {
    setValidated(false);
  } else {
    setValidated(true);
  }
};

  // Function to handle email check
  const validateEmailMatch = (value) => {
    console.log(validated)
    if (value !== email) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  return (
    <Container className="">
      <Row>
        <Col xs={12} md={9}>
          <p>
            Hey Thank you for choosing Forest Lawn's online bill payment option. You can make your monthly payment instantly with no user name or passwords to remember. All you need is your information and a credit card.
          </p>
          <p>
            PLEASE NOTE: You will need your full account information, including your Account Number. The Account Number can be found on your monthly statement.
          </p>
          <p>
            Once you have your Account Number, simply enter the required information below and click SUBMIT. Our Bill Pay system will update your account within two (2) business days of your approved credit card payment. For your convenience, we accept the following methods of payment: Visa, MasterCard and Discover.
          </p>
          <p className="red-text">
            This Bill Pay feature only applies to advance planning accounts ("ahead of need" plans). Accounts that are considered at-need ("time of loss" plans) cannot use this feature at this time. To pay for "at-need" plans, please call the park's cashier at the number provided by your Memorial Counselor.
          </p>
          <p>
            If you have any questions, please call 1 (888) 204-3131 and ask for Client Accounts. We have staff available to assist you Monday through Friday, 8:00 am to 5:00 pm.
          </p>
          <p>
            Thank you again for choosing the quickest and easiest way to make a payment with Forest Lawn.
          </p>
        </Col>
        <Col xs={12} md={3}>
          <p>Call Us Directly<br></br>
          <a href="8882043131">(888) 204-3131</a></p>
        </Col>
      </Row>
      <Row>
        <Form noValidate validated={validated}>
          <Stack gap={3} className="col-md-10">
            <div className="p-4 my-4">
              <p className="section-header">
                CARD HOLDER INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                Enter the card holder name as it appears on the front of the credit card.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Fname">
                <Form.Label column sm={2}>
                  First Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Lname">
                <Form.Label column sm={2}>
                  Last Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control  />
                </Col>
              </Form.Group>
              <p className="red-text">
                For account verification, enter the address where you receive the bill for this credit card.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Address1">
                <Form.Label column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="City">
                <Form.Label column sm={2}>
                  City
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="State">
                <Form.Label column sm={2}>
                  State
                </Form.Label>
                <Col  sm={10}>
                  <StateDropdown />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Zip">
                <Form.Label column sm={2}>
                  Zip
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" />
                </Col>
              </Form.Group>
              <p className="form-text">
                This information will be used if there is a question about your payment and 
                to send receipt confirmation. We do not sell contact information to third parties.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Phone">
                <Form.Label column sm={2}>
                  Phone
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="(000)000-0000" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Email">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    type="email" 
                    placeholder=""
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    isInvalid={email === confirmedEmail} 
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="ConfirmEmail">
                <Form.Label column sm={2}>
                  Confirm Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    type="email" 
                    name="confirmedEmail"
                    value={confirmedEmail}
                    onChange={(e) => {
                      const value = e.target.value;
                      setConfirmedEmail(value);
                      validateEmailMatch(value)
                    }}
                    required
                    isInvalid={email === confirmedEmail}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="p-4 my-4">
              <p className="section-header">
                PURCHASER INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                Please pay only one contract at a time.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Account">
                <Form.Label column sm={3}>Account #</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="contractNumber"
                    value={payment.metadata.contractNumber}
                    onChange={(e) =>
                      dispatch(updateMetadata({ contractName: payment.metadata.contractName, contractNumber: parseFloat(e.target.value) }))                    
                    }
                    required
                    isInvalid={confirmedContractNumber && confirmedContractNumber !== payment.metadata.contractNumber}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  Contract Number must match the re-entered Contract Number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="ReenterAccount">
                <Form.Label column sm={3}>Re-enter Account #</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="confirmContractNumber"
                    value={confirmedContractNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      setConfirmedContractNumber(parseFloat(value));
                      // Validate the confirmed contract number
                      validateContractNumberMatch(parseFloat(value));
                    }}
                    required
                    isInvalid={confirmedContractNumber && confirmedContractNumber !== payment.metadata.contractNumber}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  Please ensure the Contract Numbers match.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  Contract Numbers match.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Contract">
                <Form.Label column sm={3}>Name on Contract</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="contractName"
                    value={payment.metadata.contractName}
                    onChange={(e) =>
                      dispatch(updateMetadata({ contractName: e.target.value, contractNumber: payment.metadata.contractNumber }))
                    }
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="p-4 my-4">
              <p className="section-header">
                CREDIT CARD INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                We accept Visa, MasterCard, and Discover.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Amount">
                <Form.Label column sm={2}>
                  Amount (USD):
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={amount} // Display value in dollars
                    onChange={handleAmountChange}
                    min="0"
                    step="0.01" // Allow cents
                    required
                  />
                </Col>
              </Form.Group>
              <div className="payment-container">
                <div ref={paymentContainer} className="payment"></div>
              </div>
              <hr></hr>
                <Row className="footer-text">
                  <Col xs={12} md={3}>
                    <p>Copyright @  2024 FOREST LAWN MEMORIAL-PARK ASSOCATION</p>
                  </Col>
                  <Col xs={12} md={9}>
                    <p className="text-center">Thank you for your interest in Forest Lawn.</p>
                    <p>We respect your privacy and will not sell your personal information. Forest Lawn will collect and use the information you provide 
                      here to periodically email, call or text you with information about products, services, and events according to the terms of the 
                      Forest Lawn <a href="https://forestlawn.com/privacy">Privacy Policy and Terms of Use</a> , until you change your communication preferences at <a href="https://forestlawn.com/preferences">www.forestlawn.com/preferences</a>.
                    </p>
                    <p>
                      FOREST LAWN MEMORIAL-PARKS & MORTUARIES
                    </p>
                  </Col>
                </Row>
              <hr></hr>
              <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={10}>
                    <p className="footer-text">
                      ARCADIA FD 2186 | CATHEDRAL CITY FD 1847 | CITY OF INDUSTRY FD 2121 | COACHELLA FD 640 | COVINA HILLS FD 1150 | CYPRESS FD 1051
                      GLENDALE FD 656 | HOLLYWOOD HILLS FD 904 | INDIO FD 967 | LONG BEACH FD 1151 | MONROVIA FD 221 | SAN DIMAS FD 1783 | WHITTIER FD 2302
                    </p>
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
            </div>
          </Stack>
        </Form>
      </Row>
      
    </Container>
  );
}

export default Checkout;