import React, { useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Message = ({ type, reason }) => {
  let msg, img;
  switch (type) {
    case "pending":
      msg = <span>Your order has been received! Payment completion pending.</span>;
      img = "success";
      break;
    case "failed":
      msg = <span>The payment was refused. Please try a different payment method or card.</span>;
      img = "failed";
      break;
    case "error":
      msg = (
        <span>
          Error! Reason: {reason || "Internal error"}, refer to&nbsp;
          <a href="https://docs.adyen.com/development-resources/response-handling">Response handling.</a>
        </span>
      );
      img = "failed";
      break;
    default:
      msg = <span>Your payment has been submitted.</span>;
      img = "success";
  }

  return (
    <>
      <img src={`/images/${img}.svg`} className="status-image" alt={img} />
      {["failed", "error"].includes(type) ? null : <img src="/images/thank-you.svg" className="status-image" alt="thank-you" />}
      <p className="status-message my-6">{msg}</p>
    </>
  );
};

export const StatusContainer = () => {
  let { type } = useParams();
  let query = new URLSearchParams(useLocation().search);
  let reason = query ? query.get("reason") : "";
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Container className="status-container">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className="status text-center">
            <Message type={type} reason={reason} />
            <Link to="/" className="button">
              Return Home
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
